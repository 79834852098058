import { useTranslation } from "react-i18next"

import { TitleHeading, SubTitleHeading } from "../shared/headingStyles"

export function Headers() {
  const { t, ready } = useTranslation(["regForm"])
  const url = location.href
  const subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
  const portalProviderName =
    subdomain == "hertsrewards" ? "Herts Rewards" : "Vivup"
  if (!ready) {
    return null
  }
  return (
    <>
      <TitleHeading variant="h1">
        {t("regForm:header", { portalProviderName: portalProviderName })}
      </TitleHeading>
      <SubTitleHeading sx={{ textAlign: "center" }} variant="h4">
        {t("regForm:subHeader", { portalProviderName: portalProviderName })}
      </SubTitleHeading>
    </>
  )
}
